.canvas {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    max-width: 100%;
    max-height: 100%;

    background-color: transparent;

    &.hide {
        display: none;
    }
}

.toolMain {
    position: relative;
}

.toolSub {
    position: absolute;
    top: 0%;
    right: 100%;
    display: flex;
    flex-direction: row;

    &.hide {
        display: none;
    }
}

.toolSub>button {
    padding: 0.3rem;
    margin-top: 0.2rem;
    margin-right: 0.5rem;
    line-height: 1em;
    border: solid 2px #333333;
    border-radius: 50%;
    outline: none;
    background-color: aliceblue;
}

.modalDialog {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translateY(-50%) translateX(-50%);
}

.modalOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}