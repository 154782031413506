.canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: #333333;

    &.inactive {
        display: none;
    }    
}

.image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    max-width: 100%;
    max-height: 100%
}

.toolBox {
    position: absolute;
    top: 0;
    right: 0;
    margin: 1rem;
    padding: 0rem 0;

    background-color: aliceblue;
    border: solid 2px #333333;
    border-radius: 0.5rem;

    display: flex;
    flex-flow: column nowrap;
}
