.outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    background-color: rgba(0, 0, 0, 0.5);

    &.show {
        display: block;
    }

    &.hide {
        display: none;
    }
}

.inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);

    width: 200px;
}

.bar {
    width: 100%;
}