.modalDialog {
    position: absolute;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translateY(-50%) translateX(-50%);
}

.modalOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.tab {
    margin-bottom: 1rem;
}

.form {
    position: relative;
    top: 0;
}

.show {
    display: block;
}

.hide {
    display: none;
}